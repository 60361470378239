<template>
  <div class="comment-box">
    <b-input-group>
      <b-form-textarea rows="3"
        placeholder="Observații comandă ..."
        v-model="clientComment"
        :disabled="!isEditClientComment && isFulfilledOrder"
      ></b-form-textarea>
      <b-input-group-append v-if="isFulfilledOrder">
        <b-button :pressed.sync="orderOptionsButton" v-if="isEditClientComment" @click="updateClientComment" variant="outline-secondary" class="shadow-none"><b-icon icon="check2-square"></b-icon></b-button>
        <b-button :pressed.sync="orderOptionsButton" v-if="isEditClientComment" @click="isEditClientComment = false" variant="outline-secondary" class="shadow-none"> <b-icon icon="x-square"></b-icon> </b-button>
        <b-button :pressed.sync="orderOptionsButton" v-if="!isEditClientComment" @click="isEditClientComment = true" variant="outline-secondary" class="shadow-none"> <b-icon icon="pencil-square"></b-icon> </b-button>
      </b-input-group-append>
    </b-input-group>
    <div class="tags-box d-none d-md-block">
      <b-button-group>
        <b-button
          v-for="(btn, idx) in orderOptions"
          :key="idx"
          :pressed.sync="btn.state"
          variant="link" size="sm" class="mini-btn mx-1" disabled
        >
           {{ btn.caption }}
        </b-button>
      </b-button-group>
    </div>
    <span class="d-none">{{ btnStates }}</span>
  </div>
</template>

<script>
export default {
  name: 'OrderPlaceComment',
  data () {
    return {
      isEditClientComment: false,
      orderOptionsButton: false,
      orderOptions: [
        { caption: 'Portbagaj liber', state: false },
        { caption: 'Break', state: false },
        { caption: 'Animal', state: false },
        { caption: 'POS', state: false },
        { caption: 'Aer condiționat', state: false },
        { caption: 'Comision/Shopping', state: false }
      ]
    }
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    btnStates () {
      return this.orderOptions.map(btn => {
        var results
        if (btn.state === true) {
          results = btn.caption
        } else {
          results = ''
        }
        return results
      })
    },
    isFulfilledOrder () {
      return this.order.state !== 'new'
    },
    clientComment: {
      get () {
        return this.order.places[0] && this.order.places[0].comment ? this.order.places[0].comment : null
      },
      set (value) {
        this.order.places[0].comment = value
      }
    }
  },
  methods: {
    updateClientComment () {
      console.log(this.order.places[0])
      this.$store.dispatch('dispatcherDashboard/updateOrderPlace', {
        orderPlace: {
          id: this.order.places[0].id,
          order_id: this.order.id,
          lat: this.order.places[0].lat,
          long: this.order.places[0].long,
          place_id: this.order.places[0].place_id,
          name: this.order.places[0].name,
          comment: this.order.places[0].comment
        }
      })
        .then(() => {
          this.isEditClientComment = false
          this.$toasted.success('Observatii actualizate', {
            duration: 2000
          })
        })
        .catch(() => {
          this.$toasted.error('Observatii neactualizate. Verificati campurile', {
            duration: 2000
          })
        })
    }
  },
  watch: {
    btnStates: function (value) {
      this.$emit('commentAddons', value)
    }
  }
}
</script>

<style scoped>

</style>
