<template>
  <div>
    <div v-if="$store.state.isGoogleApiLoaded" class="position-relative">
      <div class="driverSearch p-0">
        <b-button  size="sm" variant="primary" @click="togglePoly">Afișează zonele</b-button>
      </div>
      <keep-alive>
      <GmapMap
        v-if="drivers"
        ref="map"
        :center="mapCenter"
        :zoom="13"
        :options="MapStyle"
        style="width: 100%; height: calc(100vh - 200px);"
        :key="''"
      >
        <template v-if="showPoly === true">
            <gmap-polygon class="mapPoly" v-for="poly in polygonsGoogleList"
              v-bind:key="'polygon-' + poly.id"
              :paths="poly.paths"
              :editable="false"
              :options="{fillColor:  '#000', fillOpacity: 0.5,strokeOpacity: 1,strokeWeight: 1, strokeColor: '#fff'}"
              :draggable="false"
              @click="showPolygonDesc($event, poly.name)"
              ref="polygon"
            ></gmap-polygon>
          </template>
          <GmapMarker
            :key="index"
            v-for="(m, index) in drivers"
            :position="{lat: m.positions.lat, lng: m.positions.lng}"

            :clickable="false"
            :icon="markerIcon(m)"
            :draggable="true"
            :label="{color: m.onSearchNow ? 'red' : '#fff', fontSize:'9px',fontWeight:'bold', text: m.driver.id.toString()}"
            @dragend="$emit('position_changed', { event: $event, driver_id: m.driver.id } )"
            @click="driverInfo(m.userId)"
          />
        <!-- </GmapCluster> -->
      </GmapMap>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import mapIcoFree from '@/assets/driverLocFree.svg'
import mapIcoBusy from '@/assets/driverLocBusy.svg'
// import mapIcoBan from '@/assets/driverLocBan.svg'
import config from '@/config'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-best-google-maps'
import ClickOutside from 'vue-click-outside'
// import GmapCluster from 'vue2-best-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues

Vue.use(VueGoogleMaps, {
  load: {
    key: config.googleMapsApiKey,
    libraries: 'places'
  }
})

export default {
  name: 'DriversMap',
  props: {
    drivers: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      polygons: [],
      polygonsRawList: [],
      showPoly: false,
      driversLocations: [],
      driversData: [],
      driverD: '',
      selected: null,
      optionsSaved: [
        { value: null, text: 'Barbu Lautaru 12' },
        { value: 'a', text: 'Aleea Florilor 7B' }
      ],
      MapStyle: {
        mapTypeControl: false,
        streetViewControl: false
      },
      showDriverInfo: false,
      Sofer: '',
      getDriverPositionsInterval: null,
      markerSearch: '',
      mapCenter: this.$store.getters['auth/companyLocation']
    }
  },
  mounted () {
    this.getSavedPolygons()
  },
  beforeDestroy () {
  },
  computed: {
    polygonsGoogleList () {
      return this.polygonsRawList.map((item) => {
        const itemNew = Object.assign({}, item)
        itemNew.paths = []
        item.paths.forEach((it) => {
          itemNew.paths.push(new window.google.maps.LatLng(it.lat, it.long))
        })
        return itemNew
      })
    },
    driversLocationsFiltered () {
      if (this.markerSearch) {
        return this.driversLocations.filter((item) => {
          return item.indicativ === this.markerSearch
        })
      }
      return this.driversLocations
    }
  },
  methods: {
    showPolygonDesc (event, name) {
      console.log(event)
      const contentString = name
      this.infoWindowPos = event.latLng
      this.infoContent = contentString
      this.infoWinOpen = true
    },
    togglePoly () {
      this.showPoly = !this.showPoly
    },
    getSavedPolygons () {
      return Vue.axios.get(config.baseApiUrl + '/api/polygons')
        .then((response) => {
          this.polygonsRawList = response.data.data.map((polygon) => {
            polygon.isVisible = true
            return polygon
          })
        })
    },
    toggleDriverInfo () {
      this.showDriverInfo = true
    },
    hideDriverInfo () {
      this.showDriverInfo = false
    },
    getDriverPositions () {
      this.$axios.get(config.baseApiUrl + '/api/drivers-positions')
        .then(response => {
          this.driversLocations = response.data
        })
    },
    getDriverData (driverId) {
      this.$axios.get(config.baseApiUrl + '/api/users/' + driverId)
        .then(response => {
          this.Sofer = response.data
        })
    },
    driverInfo (driverId) {
      this.showDriverInfo = true
      this.getDriverData(driverId)
    },
    markerIcon (data) {
      if (data.is_busy) {
        return {
          url: mapIcoBusy,
          strokeColor: '#ffffff',
          scaledSize: { width: 22, height: 22 },
          labelOrigin: { x: 11, y: 11 }
        }
      }
      return {
        url: mapIcoFree,
        strokeColor: '#ffffff',
        scaledSize: { width: 22, height: 22 },
        labelOrigin: { x: 11, y: 11 }
      }
    },
    openMessages () {
      this.$store.state.messages.isOpen = true
      this.$store.dispatch('messages/fetchConversations').then(() => {
        setTimeout(() => {
          this.$store.commit('messages/addNewConversation', {
            conversation: {
              last_message_at: null,
              interlocutor_id: this.Sofer.id,
              name: this.Sofer.name,
              phone: this.Sofer.phone,
              email: this.Sofer.email,
              has_unread: false,
              unread_count: 0
            }
          })
        }, 500)
      })
    }
  },
  watch: {
    driversLocationsFiltered: function (results) {
      if (results.length === 1) {
        this.mapCenter.lat = results[0].lat
        this.mapCenter.long = results[0].long
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style scoped>

</style>
